<template>
  <v-card class="custom-border" height="100%">
      <v-sheet style="background-color: rgba(63, 93, 208, 0.04)" rounded class="px-3 py-10 d-flex flex-column align-center" min-height="55vh">
          <div class="f20 fw600 roboto secondary-1--text text-center">
              {{ evaluation.title }}
          </div>
          <div v-html="evaluation.description ? $dompurifier(evaluation.description) : ''" class="f13 roboto secondary-1--text ma-5 mx-5"></div>
          <v-sheet color="transparent" max-width="500" class="my-10 mx-auto">
            <div class="row justify-center">
              <div class="col-6 fw500 f12" v-if="evaluation.hasOwnProperty('course_id')">
                No of attempts done: <span class="fw400 mx-2"> {{ evaluation.hasOwnProperty('student_course_evaluations_count') ? evaluation.student_course_evaluations_count : 0 }}</span>
              </div>
              <div class="col-6 fw500 f12" v-if="evaluation.hasOwnProperty('module_id')">
                No of attempts done: <span class="fw400 mx-2"> {{ evaluation.hasOwnProperty('student_module_evaluations_count') ? evaluation.student_module_evaluations_count : 0 }}</span>
              </div>
              <div class="col-6 fw500 f12 text-center">
                No. of allowed attempts: <span class="fw400 mx-2">{{ evaluation.submission_limit }}</span>
              </div>
              <div class="col-6 fw500 f12" v-if="evaluation.hasOwnProperty('course_id')">
                Date accomplished: <span class="fw400 mx-2">{{ evaluation.student_course_evaluations.length > 0 ? $dateFormat.mmDDyy(evaluation.student_course_evaluations[0].created_at) : ''}}</span>
              </div>
              <div class="col-6 fw500 f12 text-center" v-if="evaluation.hasOwnProperty('course_id')">
                Time accomplished: <span class="fw400 mx-2">{{ evaluation.student_course_evaluations.length > 0 ? $dateFormat.hhMM(evaluation.student_course_evaluations[0].created_at) : ''}}</span>
              </div>
              <div class="col-6 fw500 f12" v-if="evaluation.hasOwnProperty('module_id')">
                Date accomplished: <span class="fw400 mx-2">{{ evaluation.student_module_evaluations.length > 0 ? $dateFormat.mmDDyy(evaluation.student_module_evaluations[0].created_at) : ''}}</span>
              </div>
              <div class="col-6 fw500 f12 text-center" v-if="evaluation.hasOwnProperty('module_id')">
                Time accomplished: <span class="fw400 mx-2">{{ evaluation.student_module_evaluations.length > 0 ? $dateFormat.hhMM(evaluation.student_module_evaluations[0].created_at) : ''}}</span>
              </div>
            </div>
          </v-sheet>
          <v-alert 
            v-if="disabledStart(evaluation.submission_limit, evaluation.hasOwnProperty('student_course_evaluations_count') ? evaluation.student_course_evaluations_count : evaluation.student_module_evaluations_count)"
            outlined type="error" 
            class="col-lg-8 mx-auto"
          >
            <div class="f14 roboto fw500 error--text">You have reached the maximum number of attempts</div>
          </v-alert>
          <v-alert 
            v-else-if="evaluation.hasOwnProperty('course_id') ? evaluation.course_evaluation_questions_count === 0 : evaluation.module_evaluation_questions_count === 0"
            outlined color="error" 
            type="warning"
            class="col-lg-8 mx-auto d-flex flex-row"
          >
            <div class="f14 roboto fw500 error--text">There are no questions available in this evaluation</div>
          </v-alert>
          <div class="text-center">
            <v-btn 
              class="poppins f14 fw500 text-capitalize" 
              color="primary"
              @click="$emit('start')"
              v-if="evaluation.hasOwnProperty('course_id') ? evaluation.course_evaluation_questions_count > 0 : evaluation.module_evaluation_questions_count > 0"
              :disabled="disabledStart(evaluation.submission_limit, evaluation.hasOwnProperty('student_course_evaluations_count') ? evaluation.student_course_evaluations_count : evaluation.student_module_evaluations_count)"
            >
              Start Evaluation
            </v-btn>
          </div>
          <div class="text-center" v-if="evaluation.hasOwnProperty('student_course_evaluations_count')">
            <v-btn 
              text
              class="poppins f12 fw500 text-capitalize" 
              color="primary"
              v-if="evaluation.student_course_evaluations_count > 0"
              @click="$emit('viewLatest')"
            >
              View Latest Submission
            </v-btn>
          </div>
          <div class="text-center" v-if="evaluation.hasOwnProperty('student_module_evaluations_count')">
            <v-btn 
              text
              class="poppins f12 fw500 text-capitalize" 
              color="primary"
              v-if="evaluation.student_module_evaluations_count > 0"
              @click="$emit('viewLatest')"
            >
              View Latest Submission
            </v-btn>
          </div>
      </v-sheet>
  </v-card>
</template>

<script>
export default {
  props: ['evaluation'],
  data: () => ({
  }),
  methods: {
    disabledStart(max_attempt, attempts) {
      if(attempts >= max_attempt) {
        return true
      } else false
    }
  },

  computed: {
  }
}
</script>
