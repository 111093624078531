<template>
  <section>
    <v-sheet class="fade" color="transparent" :height="lists?'': '80vh'">
      <v-row align="center" justify="center" class="fill-height">
        <v-col cols="12" lg="9">
          <v-btn text @click="$emit('backAssessment')" class=" f14 primary--text">BACK</v-btn>
          <OnePager 
            v-if="!data.show_each_questions || reviewAnswers" 
            :answers="answers" 
            :data="data"
            :errorFile="errorFile"
            @findFile="findFile"
            @getDropFiles="getDropFiles"
            @submit="$emit('submit')"
          />
          <MultiPage 
            v-else
            :answers="answers"
            :data="data"
            :errorFile="errorFile"
            @getDropFiles="getDropFiles"
            @findFile="findFile"
            @submit="$emit('submit')"
          />
        </v-col>
      </v-row>
      
      <input
        type="file"
        ref="file"
        class="d-none"
        accept=".pdf,.docx,.jpg,.png,.jpeg"
        @change="accept"
      />
      
    </v-sheet>
  </section>
</template>

<script>
import MultiPage from '../../components/user/assessment/MultiPage.vue'
import OnePager from '../../components/user/assessment/OnePager.vue'

export default {
  props: ['data', 'answers', 'reviewAnswers'],

  components: {
    MultiPage,
    OnePager
  },

  data: () => ({
    lists: true,
    indexFile: null,
    formHasErrors: false,
    errorFile: false,
  }),

  methods: {
    findFile(index) {
      this.indexFile = index
      this.$refs.file.click()
    },

    accept() {

      if(!['image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'].includes(this.$refs.file.files[0].type)) {
        this.errorFile = true
        this.$refs.file.value = ''
        this.answers[this.indexFile].errorMessages = "File type not supported"
        this.answers[this.indexFile].file = null
        this.answers[this.indexFile].loading = false
      } else {
        this.answers[this.indexFile].loading = true
        this.errorFile = false
        this.answers[this.indexFile].file = this.$refs.file.files[0]
        this.answers[this.indexFile].errorMessages = ''
        this.$refs.file.value = ''
        setTimeout(()=>{
          this.answers[this.indexFile].loading = false
        }, 3000)
      }
      
      // return false
    },

    getDropFiles(item){
      var { index, file } = item
      this.indexFile = index
      this.$refs.file.files = file
      this.accept()
    },
  },

}
</script>