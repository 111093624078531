<template>
    <v-container>
        <v-btn
            dense
            text class="f12 text-capitalize primary-font"
            color="primary"
            @click="back">
            Back
        </v-btn>
        <v-card class="custom-border border poppins mb-3 white--text" color="primary">
            <v-card-title class="mb-5">
                {{  evaluation.title }}
            </v-card-title>
            <v-card-text v-html="evaluation.description ? $dompurifier(evaluation.description) : ''" class="f11 white--text"></v-card-text>
        </v-card>

        <v-card class="custom-border border poppins mb-2" v-for="(question, i) in questions" :key="question.id" v-if="!loading">
            <v-card-text class="poppins fw500 f12 secondary-1--text pb-0">QUESTION</v-card-text>
            <v-card-text class="f12 py-0">
               <span v-html="question.question ? $dompurifier(question.question) : ''"></span><span v-if="question.is_require_to_answer">*</span>
            </v-card-text>
            <v-card-text v-if="error.includes(i)">
                <span class="danger--text">This is required.</span>
            </v-card-text>
            <v-slide-group
                show-arrows
                class="pa-3"
                >
                <v-slide-item
                    v-for="img in question.image"
                    :key="img.id"
                >
                    <v-img
                        :src="img.url"
                        contain
                        max-width="250"
                        class="pointer-default"
                    />
                </v-slide-item>
            </v-slide-group>
            <v-divider />
            <RadioButtons v-if="question.type_of_question === 'multiple_choice'" :choices="question.data" :index="i" @saveAnswer="saveAnswer" :preview="false" :answer="null"/>
            <Checkboxes v-if="question.type_of_question === 'checkbox'" :choices="question.data" :index="i" @saveAnswer="saveAnswer" :preview="false" :answer="null"/>
            <Dropdown v-if="question.type_of_question === 'dropdown'" :choices="question.data" :index="i" @saveAnswer="saveAnswer" :preview="false" :answer="null"/>
            <DateInput v-if="question.type_of_question === 'date'" :index="i" @saveAnswer="saveAnswer" :preview="false" :answer="null"/>
            <TimeInput v-if="question.type_of_question === 'time'" :index="i" @saveAnswer="saveAnswer" :preview="false" :answer="null"/>
            <v-card-text v-if="question.type_of_question === 'short_answer'">
                <v-text-field class=" col-sm-6 poppins f12" outlined dense hide-details @change="addText($event, i)"></v-text-field>
            </v-card-text>
            <v-card-text v-if="question.type_of_question === 'paragraph'">
                <v-textarea class=" col-sm-6 poppins f12" outlined dense hide-details auto-grow clearable @change="addText($event, i)"></v-textarea>
            </v-card-text>
            <v-card-text v-if="question.type_of_question === 'linear_scale'">
                <LinearScale :question="question" :index="i" @saveAnswer="saveAnswer" :preview="false" :answer="null"/>
            </v-card-text>
            <v-card-text v-if="question.type_of_question === 'checkbox_grid'">
                <Grid :question="question" type_of_question="checkbox_grid" :index="i" @saveAnswer="saveAnswer" :preview="false" :answer="null"/>
            </v-card-text>
            <v-card-text v-if="question.type_of_question === 'multiple_choice_grid'">
                <Grid :question="question" type_of_question="multiple_choice_grid" :index="i" @saveAnswer="saveAnswer" :preview="false" :answer="null"/>
            </v-card-text>
            <v-card-text v-if="question.type_of_question === 'file_upload'">
                <FileInput :index="i" @saveAnswer="saveAnswer" :answer="answers[i]" @removeAttachment="removeAttachment"/>
            </v-card-text>
        </v-card>
        
        <circular v-if="loading" />

        <v-btn
            dense
            class="f12 text-capitalize primary-font"
            color="primary"
            @click="submit">
            Submit
        </v-btn>

        <LeaveAlert :dialog="dialog" @cancel="dialog = false" @submit="submit"/>
    </v-container>
</template>

<script>
import RadioButtons from '../../components/user/evaluation/RadioButtons.vue'
import Checkboxes from '../../components/user/evaluation/Checkboxes.vue'
import Dropdown from '../../components/user/evaluation/Dropdown.vue'
import DateInput from '../../components/user/evaluation/DateInput.vue'
import TimeInput from '../../components/user/evaluation/TimeInput.vue'
import Grid from '../../components/user/evaluation/Grid.vue'
import LinearScale from '../../components/user/evaluation/LinearScale.vue'
import LeaveAlert from '../../components/user/evaluation/LeaveAlert.vue'
import FileInput from '../../components/user/evaluation/FileInput.vue'

export default {
    props: ['evaluation'],
    components: {
        RadioButtons,
        Checkboxes,
        DateInput,
        TimeInput,
        LinearScale,
        Grid,
        LeaveAlert,
        Dropdown,
        FileInput
    },
    data: () => ({
        dialog: false,
        questions: [],
        answers: [],
        loading: false,
        error: []
    }),
    created(){
        this.loading = true

        if(this.evaluation.hasOwnProperty('course_evaluation')){
            this.$api.get(`/user/course/evaluations/${this.evaluation.id}`).then(res => {
                let _evaluation = res.data.data
                if(_evaluation.is_shuffle_questions) {
                    this.questions = res.data.data.course_evaluation_questions.sort(() => Math.random() - 0.5)
                } else {
                    this.questions = res.data.data.course_evaluation_questions
                }
                this.setAnswers(_evaluation)
            })
        } else {
            this.$api.get(`/user/module/evaluations/${this.evaluation.id}`).then(res => {
                let _evaluation = res.data.data
                if(_evaluation.is_shuffle_questions) {
                    this.questions = res.data.data.module_evaluation_questions.sort(() => Math.random() - 0.5)
                } else {
                    this.questions = res.data.data.module_evaluation_questions
                }
                this.setAnswers(_evaluation)
            })
        }
    },
    computed: {
        getColorByBgColor() {
            if(!localStorage.getItem('customization')) return '#ffffff'
            let _bg = JSON.parse(localStorage.getItem('customization')).navigation_background_color
            return (parseInt(_bg.replace('#', ''), 16) > 0xffffff / 2) ? 'black--text' : 'white--text';
        }
    },
    methods: {
        setAnswers(_evaluation){
            this.questions.forEach((item, i) => {
                this.answers.push({
                    id: _evaluation.hasOwnProperty('course_id') ? _evaluation.course_id : _evaluation.module_id,
                    evaluation_id: _evaluation.id,
                    evaluation_question_id: item.id,
                    type_of_question: item.type_of_question,
                    answer: '',
                    file_attachment: '',
                })
            })
            this.loading = false
        },

        back(){
            this.$emit('back')
        },

        submit(){
            this.error = []
            this.questions.forEach((item, i) => {

                if(item.is_require_to_answer) {
                    if(item.type_of_question === 'file_upload') {
                        if(!this.answers[i].file_attachment || this.answers[i].file_attachment === 0 ||  this.answers[i].file_attachment === ''){
                            this.error.push(i)
                        }
                    } else {
                        if(!this.answers[i].answer || this.answers[i].answer.length === 0 ||  this.answers[i].answer === ''){
                            this.error.push(i)
                        }
                    }
                }
            })

            if(this.error.length === 0){
                this.$emit('submit', this.answers)
            }
        },

        saveAnswer(e) {
            let { index, answer } = e
            if(this.answers[index].type_of_question === 'file_upload') {
                this.answers[index].file_attachment = answer
            } else {
                this.answers[index].answer = { answer: answer }
            }
        },

        removeAttachment(index) {
            this.answers[index].file_attachment = ''
        },

        addText(val, index) {
            this.saveAnswer({ index: index, answer: val })
        }
    }
}
</script>