<template>
  <section color="transparent">
    <section class="d-flex flex-row mb-3">
      <v-btn text v-if="!loading && !quizStart && !viewLatestSubmitted && !evaluationStart && !viewLatestEval" color="primary" dense class="px-1" @click="$router.push({ name: 'User Course Details', params: { id: $route.params.course_uuid}})">
        <v-icon small>
          mdi-chevron-left
        </v-icon>
        BACK
      </v-btn>
      <v-spacer/>
      <v-btn 
        :disabled="!isCompleted()"
        class="hidden-sm-and-down"
        @click="generate"
        :loading="certificateLoading"
        v-if="!loading && !quizStart && !viewLatestSubmitted && !evaluationStart && !viewLatestEval" color="primary" dense>
        <v-icon small class="mr-1">
          mdi-certificate-outline
        </v-icon>
        GENERATE CERTIFICATE
      </v-btn>
    </section>
    <v-row v-if="!loading && !quizStart && !viewLatestSubmitted && !evaluationStart && !viewLatestEval">
      <v-col cols="12" md="4" class="order-last order-md-first">
        <v-sheet class="custom-border border pa-4 fade">
          <div class="text-capitalize secondary-font f14 fw600 secondary-1--text no-spacing my-3 ">
              COURSE OUTLINE
            <section class="d-flex flex-row align-center secondary-1--text">
              <v-progress-linear 
                height="8"
                :value="getProgress(this.course)"
                rounded
                color="success"
                background-color="grey lighten-3"
                class="mr-2"
                style="width: 88%"
              ></v-progress-linear>
              <div class="d-flex flex-row align-center ">{{ getProgress(this.course) }} %</div>
            </section>
          </div>
          <v-sheet class="overflow-y-auto" max-height="310" min-height="42vh"  id="parent">
            <MaterialTimeline 
              :course="course" 
              :modules = "course.modules"
              :current_index="current_index"
              :course_content="course_content"
              @goTo="goTo"
            />
          </v-sheet>
          <div class="d-flex align-center ma-5 justify-center ">
            <v-btn outlined class="f12 mx-2"
              :disabled="this.current_index === 0"
              @click="prev" dense>
              <!-- <v-icon>mdi-chevron-left</v-icon> -->
              Prev
            </v-btn>
            <v-btn 
                outlined class="mx-2 f12 d-flex flex-wrap" 
                color="primary" 
                @click="finishCourse"
                dense
                v-if="current_index === (course_content.length - 1) && (course_content[current_index].hasOwnProperty('is') && (course_content[current_index].is === 'topic' || course_content[current_index].is === 'sub-topic') ) && !course_content[current_index].student_done_status"
              >
              Complete
            </v-btn>
            <v-btn 
              v-else-if="current_index !== (course_content.length - 1)"
              outlined class="mx-2 f12" 
              color="primary" 
              @click="next"
              :loading="mark"
              dense
            >
              Next
            </v-btn>
            <!-- <v-btn
              outlined class="mx-2 f12" 
              color="primary" 
              @click="next"
              dense
              :loading="mark"
              v-if="current_index !== (course_content.length - 1) && course_content[current_index].hasOwnProperty('is')">

              {{ course_content[current_index].student_done_status ? 'Next' : 'Mark as Completed' }}

            </v-btn> -->

            <!-- <v-btn 
              outlined class="mx-2 f12" 
              color="primary" 
              @click="next"
              dense
              :loading="mark"
            >
              {{ course_content[current_index].hasOwnProperty('is') ? (course_content[current_index].is == 'topic' || course_content[current_index].is=='sub-topic') ? course_content[current_index].student_done_status ? current_index !== (course_content.length - 1) ? 'Next' : 'Completed' : 'Mark as Completed' : current_index !== (course_content.length - 1) ? 'Next' : 'Completed'  : current_index !== (course_content.length - 1) ? 'Next' : 'Completed' }}
          
            </v-btn> -->
            <!-- <v-btn 
              outlined class="mx-2 f12" 
              color="primary" 
              @click="next"
              dense
              :loading="mark"
            >
              Next
            </v-btn> -->
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="8" class="py-0">
        <v-sheet class="custom-border border fade">
          <div class="mt-3" v-if="this.course_content[this.current_index].is == 'topic' || this.course_content[this.current_index].is=='sub-topic'">
            <v-card v-if="this.course_content[this.current_index].learning_material" flat>
              <section class="d-flex justify-end" v-if="this.course_content[this.current_index].learning_material.file_type !== 'mp4' && this.course_content[this.current_index].learning_material.file_type !== 'youtube'">
                <v-btn icon dense @click="toggleFullscreen">
                  <v-icon >
                    mdi-fullscreen
                  </v-icon>
                </v-btn>
              </section>
              <vimeo v-if="(this.course_content[this.current_index].learning_material.file_type == 'mp4' || this.course_content[this.current_index].learning_material.file_type == 'youtube') && showVimeo"
                  :uri="this.course_content[this.current_index].learning_material.url"
                  :type="this.course_content[this.current_index].learning_material.file_type"
                  :data="this.course_content[this.current_index]"
                  @start="videoStart=true"
                  @end="videoEnd=true"
                />   
              <div id="container-frame" class="container-frame" v-else-if="this.course_content[this.current_index].learning_material.file_type == 's3_link' || this.course_content[this.current_index].learning_material.file_type == 'scorm_file'">
                <iframe 
                  id="material-iframe"
                  v-if="this.course_content[this.current_index].learning_material.url.split('.').slice(-1)[0] === 'ppt' || this.course_content[this.current_index].learning_material.url.split('.').slice(-1)[0] === 'docx'"
                  :src="`https://view.officeapps.live.com/op/embed.aspx?src=${this.course_content[this.current_index].learning_material.url}`"
                  class="responsive-iframe"
                  allowfullscreen="true"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allow="fullscreen"
                />
                <iframe 
                  id="material-iframe"
                  v-else
                  :src="this.course_content[this.current_index].learning_material.url"
                  class="responsive-iframe"
                  allowfullscreen="true"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allow="fullscreen"
                />
              </div>
              <docx v-else-if="this.course_content[this.current_index].learning_material.file_type != 'youtube' || this.course_content[this.current_index].learning_material.file_type != 'mp4'" :file="this.course_content[this.current_index].learning_material.url"/> 
              <circular v-else/>
              <div class="ma-1 mb-2" v-if="this.course_content[this.current_index].learning_material.file_type == 'pdf' || this.course_content[this.current_index].learning_material.file_type == 's3_link'">
                <v-btn @click="downloadMaterial(course_content[current_index].learning_material.url, course_content[current_index].learning_material.file_type)" dense class="primary-font f12 text-capitalize primary--text" text>
                  <v-icon small>
                    mdi-download-outline
                  </v-icon>
                  Download material
                </v-btn>
              </div>
            </v-card>
            <v-sheet height="50vh" class="d-flex align-center justify-center" v-else>
              No learning material found
            </v-sheet>   
          </div>
          <section v-else class="pa-3">
            <StartScreenEvaluation @start="startEvaluation" v-if="(this.course_content[this.current_index].hasOwnProperty('course_evaluation') || this.course_content[this.current_index].hasOwnProperty('module_evaluation')) && !viewLatestEval" :evaluation="this.course_content[this.current_index]"  @viewLatest="viewLatestEval = true"/>
            <StartScreen :link="false" :data="assessment" @start="startQuiz" v-else-if='!quizLoading' @viewLatest="viewLatest"/>
            <!-- <circular v-if="quizLoading" class="ma-auto mt-8"/> -->
          </section>
        </v-sheet>
      </v-col>
    </v-row>
      <Quiz 
        v-if="quizStart && !viewLatestSubmitted"
        :data="assessment" 
        :answers="answers" 
        @submit="showSubmitDialog" 
        :reviewAnswers="reviewAnswers"
        @backAssessment="backAssessment"
      />
      <LeaveAlert 
        :dialog="dialog" 
        @submit="leaveQuiz"
        @cancel="dialog.status=false"
      />
      <AlertLeave
        :dialog="evalLeaveDialog" 
        @submit="leaveEvaluation"
        @cancel="evalLeaveDialog.status=false"
      />
      <DialogSubmit 
        :dialog="submitDialog" 
        @submit="submit"
        @cancel="submitDialog=false"
        @review="review"
      />
      <DialogSubmit 
        :dialog="submitEvalDialog" 
        @submit="submitEvaluation"
        @cancel="submitEvalDialog=false"
        @review="reviewEval"
      />
      <DialogSuccess :dialog="successDialog"/>
      <SuccessDialog :dialog="evalSuccessDialog"/>
      <LatestSubmission v-if="viewLatestSubmitted && !quizStart" :data="assessment" :answers="assessment.assessment_scores[0]" @backSubmitted="viewLatestSubmitted = false"/>
      <Evaluation v-if="evaluationStart" :evaluation="this.course_content[this.current_index]" @back="evalLeaveDialog = {status: true, action: 'leave'}" @submit="confirmEvaluation"/>
      <LatestEvaluation v-if="viewLatestEval" :evaluation="this.course_content[this.current_index]" @back = "viewLatestEval = false"/>
      <!-- <FinishCourseDialog :dialog="finishDialog" /> -->
      <CertificateDialog 
        :dialog="certificateDialog"
        :loading="certificateLoading"
        :url="certificateURL"
        @close="certificateDialog = false"
        />
    <circular v-if="loading"/>
  </section>
</template>

<style>
.container-frame {
position: relative;
overflow: hidden;
width: 100%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
height: 500px;
}

@media only screen and (max-width: 768px){
.responsive-iframe {
  height: 300px;
  width: 100%;
}
}

</style>

<script>
import Quiz from './Quiz.vue';
import { mapMutations, mapActions, mapState } from 'vuex';
import LeaveAlert from '../../components/user/assessment/LeaveAlert.vue';
import StartScreen from '../../components/user/assessment/StartScreen.vue';
import DialogSubmit from '../../components/user/assessment/DialogSubmit.vue';
import DialogSuccess from '../../components/user/assessment/DialogSuccess.vue';
import MaterialTimeline from '../../components/user/course/MaterialTimeline.vue';
import LatestSubmission from './LatestSubmission.vue';
import StartScreenEvaluation from '../../components/user/evaluation/StartScreen.vue';
import Evaluation from './Evaluation.vue';
import SuccessDialog from '../../components/user/evaluation/SuccessDialog.vue'
import AlertLeave from '../../components/user/evaluation/AlertLeave.vue'
import LatestEvaluation from '../../components/user/evaluation/LatestEvaluation.vue';
import FinishCourseDialog from '../../components/user/course/FinishCourseDialog.vue';
import CertificateDialog from '../../components/main/CertificateDialog.vue';

export default {
  components: {
    MaterialTimeline,
    StartScreen,
    Quiz,
    LeaveAlert,
    DialogSubmit,
    DialogSuccess,
    LatestSubmission,
    StartScreenEvaluation,
    Evaluation,
    SuccessDialog,
    AlertLeave,
    LatestEvaluation,
    FinishCourseDialog,
    CertificateDialog
  },
  data: () => ({
    loading: true,
    videoStart: false,
    videoEnd: false,
    current_index: null,
    quizStart: false,
    answers: [],
    reviewAnswers: false,
    reviewAnswersEval: false,
    dialog: {
      status: false,
      action: ''
    },
    evalLeaveDialog: {
      status: false,
      action: ''
    },
    reload: false,
    submitDialog: false,
    submitEvalDialog: false,
    successDialog: false,
    post_type: '',
    quizLoading: false,
    showVimeo: true,
    viewLatestSubmitted: false,
    evaluationStart: false,
    evalAnswers: null,
    evalSuccessDialog: false,
    viewLatestEval: false,
    url: null,
    finishDialog: false,
    mark: false,
    certificateLoading: false,
    certificateDialog: false,
    certificateURL: null
  }),
  beforeMount() {
    window.addEventListener("beforeunload", this.beforeReload);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.beforeReload);
    });
  },
  computed: {
    ...mapState({
      customization: (state) => state.customization
    }),
    
    ...mapState('usr', {
      course: (state) => state.object,
      assessment: (state) => state.assessments,
      startAssessment: (state) => state.startAssessment,
    }),

    getOutlinedColor(){
      return this.customization.hasOwnProperty('secondary_buttons_color') ? this.customization.secondary_buttons_color : '#b52210'
    },

    course_content(){
      let _course_content = []
      if(this.course) {
        if(this.course.hasOwnProperty('modules')) {
          this.course.modules.forEach((item, i) => {
            if(item.valid_until === 'anytime' || !this.locked(item.valid_until)) {
              if(item.data && item.data.length > 0 ){
                item.data.forEach((module) => {
                  _course_content.push({expanded: i, ...module})

                  if(module.topic_has_assessments) {
                    module.topic_has_assessments.forEach((assessment) => {
                      _course_content.push({expanded: i, ...assessment})
                    })
                  }
                  if(module.sub_topic_has_assessments) {
                    module.sub_topic_has_assessments.forEach((assessment) => {
                      _course_content.push({expanded: i, ...assessment})
                    })
                  }

                })
              }
              item.module_evaluations.forEach((_item) => {
                _course_content.push({expanded: i, ..._item, module_evaluation: true})
              })
            }
          })
          let j = this.course.modules.length
          this.course.course_evaluations.forEach((item) => {
            _course_content.push({expanded: j, ...item, course_evaluation: true})
            
          })
        }
      }
      return _course_content
    },

    // disableButtonNext(){
    //   if((this.current_index + 1) != this.course_content.length){
    //     if(!this.course_content[this.current_index].hasOwnProperty('course_evaluation') && !this.course_content[this.current_index].hasOwnProperty('module_evaluation')) {
    //       if(this.course_content[this.current_index].is == 'topic' || this.course_content[this.current_index].is == 'sub-topic') {
          
    //         if(this.course_content[this.current_index].learning_material.file_type != 'youtube' || this.course_content[this.current_index].learning_material.file_type != 'mp4') {
    //           return false
    //         }
    //         if(this.course_content[this.current_index + 1].is == 'topic' || this.course_content[this.current_index + 1].is == 'sub-topic'){
    //           return !this.videoEnd && !this.course_content[this.current_index].student_done_status
    //         } else {
    //           if(this.course_content[this.current_index + 1].assessment_score.score) {
    //             return false
    //           }
    //           if(this.videoEnd){
    //             if(this.course_content[this.current_index + 1].assessment_score.score){
    //               return !this.lockedAssessment(JSON.parse(this.course_content[this.current_index + 1].due_date))
    //             } else return this.lockedAssessment(JSON.parse(this.course_content[this.current_index + 1].due_date))
    //           } else return true
    //           // return this.course_content[this.current_index + 1].due_date ? this.course_content[this.current_index].student_done_status ? false : true : true
    //         }
    //       } else {
    //         if(this.course_content[this.current_index + 1].is == 'topic' || this.course_content[this.current_index + 1].is == 'sub-topic'){
    //           return !this.course_content[this.current_index].assessment_score.score
    //         } else {
    //           if(this.course_content[this.current_index + 1].assessment_score.score) {
    //             return false
    //           }
    //           if(this.course_content[this.current_index].assessment_score.score){
    //             return this.lockedAssessment(JSON.parse(this.course_content[this.current_index + 1].due_date))
    //           } else return false
    //         }
    //       }
    //     }
    //   } else {
    //     return true
    //   }
    // }
  },
  mounted(){
    document.addEventListener('fullscreenchange', this.handleFullscreenChange);

    if(this.$route.params.selected_index) {
      this.current_index = this.$route.params.selected_index
    }
    this.showCourse()
  },

  beforeUnmount() {
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
  },

  methods: {
    ...mapMutations(['alertMutation']),

    ...mapActions('usr', ['showCourseAction', 'getAssessmentAction', 'storeAssessmentAnswersAction', 'showNextContentAction', 'generateCertificateAction', 'markCourseAsCompletedAction']),

    generate(){
      this.certificateLoading = true
      this.generateCertificateAction({course_id: this.course.id}).then(res => {
        this.certificateDialog = true
        this.certificateLoading = false
        this.certificateURL = res
      }).catch(res => {
        this.certificateLoading = false
      })
    },

    isCompleted(){
      let progress = Math.round(((this.course.total_done_assessments + this.course.total_done_topics + this.course.total_done_sub_topics) / (this.course.assessments_count + this.course.topics_count + this.course.sub_topics_count)) * 100)
      return progress === 100
    },
    
    showCourse() {
      this.showCourseAction(this.$route.params.course_uuid).then(()=>{
        this.loading = false
        if ( this.current_index == null) {
          if(this.$route.query.uuid) {
            this.current_index = this.course_content.findIndex(item => item.uuid === this.$route.query.uuid)
          }
          // this.course_content.forEach((item, i) => {
          //   if(item.is === 'topic' || item.is === 'sub-topic') {
          //     if(item.student_done_status) {
          //       this.current_index = i
          //     }
          //   } else {
          //     if(item.assessment_score.score) {
          //       this.current_index = i
          //     }
          //   }
          // })
          if ( this.current_index == null) {
            this.current_index = 0
          }
        }
      })
    },

    async downloadMaterial(_url, type) {
      return await new Promise((resolve, reject) => {
        this.$api.post('user/download/course/learning-material', {
          url: _url,
          file_type: _url.split('.').pop()
        }).then(res =>  
          window.open(res.data.downloadable_url)
        ).catch(errs => 
          this.alertMutation({
            show: true,
            text: 'Unexpected error encountered while downloading.',
            type: "error"
          })
        )
      })
    },

    goTo(index) {
      this.current_index = index
      if(this.course_content[this.current_index].is == 'topic' || this.course_content[this.current_index].is == 'sub-topic') {
      this.showNextContentAction({
        course_uuid: this.$route.params.uuid, 
        model_uuid: this.course_content[this.current_index].uuid, 
        model_type: this.course_content[this.current_index].is == 'topic' ? 'Topic' : 'SubTopic',
          save_progress: true,
          course_id: this.course.id
      }).then(()=>{
        this.videoStart = false
        this.videoEnd = false
        this.showCourse()
        this.showVimeo = false
      })
      }
    },

    next(){
      if(this.course_content[this.current_index].is == 'topic' || this.course_content[this.current_index].is == 'sub-topic') {
        if(this.course_content[this.current_index].student_done_status) {
          if(this.current_index !== (this.course_content.length - 1)){
            this.current_index += 1
          }
        } else {
          this.mark = true
          this.showNextContentAction({
            course_uuid: this.$route.params.uuid, 
            model_uuid: this.course_content[this.current_index].uuid, 
            model_type: this.course_content[this.current_index].is == 'topic' ? 'Topic' : 'SubTopic',
            save_progress: true,
            course_id: this.course.id
          }).then(()=>{
            this.videoStart = false
            this.videoEnd = false
            this.showCourse()
            this.mark = false
            this.showVimeo = false
            if(this.current_index !== (this.course_content.length - 1)){
              this.current_index += 1
            }
          })
        }
      } else {
        if(this.current_index !== (this.course_content.length - 1)){
          this.current_index += 1
        }
      }

      
      
    },

    finishCourse() {
      if(this.course_content[this.current_index].is == 'topic' || this.course_content[this.current_index].is == 'sub-topic') {
        this.showNextContentAction({
          course_uuid: this.$route.params.uuid, 
          model_uuid: this.course_content[this.current_index].uuid, 
          model_type: this.course_content[this.current_index].is == 'topic' ? 'Topic' : 'SubTopic',
          save_progress: true,
          course_id: this.course.id
        }).then(()=>{
          this.videoStart = false
          this.videoEnd = false
          this.showCourse()
          this.showVimeo = false
        })
      }

      if(this.current_index !== (this.course_content.length - 1)){
        this.current_index += 1
      }

      this.finishDialog = true

      setTimeout(() => {
        this.finishDialog = false
      }, 3000)
    },

    prev(){
      this.current_index -= 1
    },

    fetchAssessment() {
      this.quizLoading = true
      this.answers = []
      this.getAssessmentAction(this.course_content[this.current_index].uuid)
        .then(_ => {
        
        if(this.assessment.shuffle_questions) this.shuffleQuestions()
        
        this.assessment.assessment_questions.forEach(el => {
          this.answers.push({
            question_id: el.id,
            answer: "",
            is_require_support_answer: el.require_support_answer,
            is_require_file_attachment: el.require_file_attachment,
            support_answer: "",
            file: null,
            errorMessages: '',
            loading: false
          })
        });

        if(this.assessment.shuffle_answers) this.shuffleAnswers()

        this.quizLoading = false
        
        this.showCourse()
      })
    },
    
    startQuiz() {
      this.quizStart=true

      this.$store.commit('usr/startAssessmentMutation', {
        action: 'start',
        status: true
      })
    },

    showSubmitDialog() {
      if(!this.reviewAnswers) {
        this.submitDialog=true
      } else this.submit()
    },

    submit() {
      this.submitDialog=false
      this.post_type='submitted'
      this.$store.commit('usr/startAssessmentMutation', {
        action: 'submitted',
        status: false
      })

      this.savedAnswers()
      this.successDialog=true
      this.quizStart = false
      this.reviewAnswers =  false
    },  

    review(){
      this.submitDialog = false
      this.reviewAnswers = true
    },

    reviewEval(){
      this.submitEvalDialog = false
      this.reviewAnswersEval = true
    },

    leaveQuiz(){
      this.loading = true
      this.post_type='left'
      this.$store.commit('usr/startAssessmentMutation', {
        action: 'left',
        status: false
      })
      this.quizStart = false
      this.dialog = {
        status: false,
        action: ""
      }
      this.savedAnswers()
    },

    backAssessment(){
      if(this.quizStart){
        this.dialog = {
          status: true,
          action: "leave"
        }
      } else this.$router.back()
    },

    savedAnswers() {
      var form = new FormData()
      form.append('assessment_id', this.assessment.id)
      form.append('post_type', this.post_type)
      form.append('course_id', this.assessment.course_id)
      this.answers.forEach((el, i) => {
        form.append('question_id[]', el.question_id)
        form.append('answer[]', el.answer)
        form.append('support_answer[]', el.support_answer)
        form.append(`file[${i}]`, el.file) 
        form.append('is_require_support_answer[]', el.is_require_support_answer)
        form.append('is_require_file_attachment[]', el.is_require_file_attachment)
      })

      this.storeAssessmentAnswersAction(form).then(_ => {
        this.fetchAssessment()
      })

    },

    checkAnswers() {
      let status = true;
      this.answers.every(el => {
        el.errorMessages = ''
        if(!el.answer) {
          el.errorMessages = 'Answer is required'
          status = false
          return false
        }

        if(el.is_require_file_attachment && !el.file) {
          el.errorMessages = 'File is required'
          status = false
          return false
        }

        if(el.is_require_support_answer && !el.support_answer) {
          el.errorMessages = 'Support answer is required'
          status = false
          return false
        }
        status = true
        return true
      })

      return status
    },

    // beforeReload(e) {
    //   if(!this.start) return
    //   console.log(e);
      // e.preventDefault();
      // e.returnValue='Are you sure you want to exit?'
    // },

    shuffleQuestions() {
      return this.assessment.assessment_questions.sort(() => Math.random() - 0.5)
    },

    shuffleAnswers() {
      this.assessment.assessment_questions.forEach(questions => {
        if(questions.assessment_answer.choices) {
          questions.assessment_answer.choices.sort(() => Math.random() - 0.5)
        }
      })
    },

    locked(item) {

      let today = new Date().toISOString().split("T")[0];

      if(today > item.valid_until) return false
      return true
    },

    lockedAssessment(due_date) {
      if(due_date) {
        let start = new Date(due_date.start)
        let due = new Date(due_date.end)
        let today = new Date();
        
        if(due_date.end !== '' && due_date.start !== ''){
          if(start < today) {
            return false
          } else if(start >= today && today >= due) {
            return false
          } return true
        } else if(due_date.end === '' && due_date.start){
          if(today >= start) return false
          else return true
        }
        return false
      } return true
    },

    viewLatest() {
      this.viewLatestSubmitted = true
      this.quizStart = false
    },

    startEvaluation() {
      this.evaluationStart = true
    },

    leaveEvaluation(){
      this.evalLeaveDialog.status = false
      this.evaluationStart = false
    },

    confirmEvaluation(data) {

      this.evalAnswers = data
      this.submitEvalDialog = true
      
    },

    submitEvaluation() {
        this.reviewAnswersEval = false

        let _form = new FormData()

        const config = { 
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
          }
        }

        if(this.course_content[this.current_index].hasOwnProperty('course_evaluation')) {
          
          this.evalAnswers.forEach((item, i) => {
            _form.append(`course_id[${i}]`, item.id)
            _form.append(`course_evaluation_id[${i}]`, item.evaluation_id)
            _form.append(`course_evaluation_question_id[${i}]`, item.evaluation_question_id)
            _form.append(`answer[${i}]`, JSON.stringify(item.answer))
            _form.append(`file_attachment[${i}]`, item.file_attachment)
          })

          this.$api.post(`user/course/evaluations`, _form, config).then(res => {
            this.submitEvalDialog = false
            this.evalSuccessDialog = true
            this.evaluationStart = false
            this.showCourse()
          })
        } else {  
          
          this.evalAnswers.forEach((item, i) => {
            _form.append(`module_id[${i}]`, item.id)
            _form.append(`module_evaluation_id[${i}]`, item.evaluation_id)
            _form.append(`module_evaluation_question_id[${i}]`, item.evaluation_question_id)
            _form.append(`answer[${i}]`, JSON.stringify(item.answer))
            _form.append(`file_attachment[${i}]`, item.file_attachment)
          })
          
          this.$api.post(`user/module/evaluations`, _form, config).then(res => {
            this.submitEvalDialog = false
            this.evalSuccessDialog = true
            this.evaluationStart = false
            this.showCourse()
          })
        }
    },

    getProgress(item){
      let progress = Math.round(((item.total_done_assessments + item.total_done_topics + item.total_done_sub_topics) / (item.assessments_count + item.topics_count + item.sub_topics_count)) * 100)

      if(progress === 100) {
        this.markCourseAsCompletedAction({ course_id: this.course.id })
      }
      return progress ? progress : 0
    },
    handleFullscreenChange() {
      const materialContainer = document.getElementById("material-iframe");
      if (!document.fullscreenElement) {
        materialContainer.style.height = '500px'
      }
    },
    toggleFullscreen() {
      const embedContainer = document.getElementById("container-frame");
      const materialContainer = document.getElementById("material-iframe");

      if (embedContainer.requestFullscreen) {
        if (document.fullscreenElement) {
          document.exitFullscreen();
          materialContainer.style.height = '500px'
        } else {
          this.originalHeight = materialContainer.style.height;
          embedContainer.requestFullscreen();
          materialContainer.style.height = '100vh'
        }
      } else if (embedContainer.mozRequestFullScreen) { // For Firefox
        if (document.mozFullScreenElement) {
          document.mozCancelFullScreen();
          materialContainer.style.height = this.originalHeight
        } else {
          this.originalHeight = materialContainer.style.height;
          embedContainer.mozRequestFullScreen();
          materialContainer.style.height = '100vh'
        }
      } else if (embedContainer.webkitRequestFullscreen) { // For Chrome, Safari, and Opera
        if (document.webkitFullscreenElement) {
          document.webkitExitFullscreen();
          materialContainer.style.height = '500px'
        } else {
          this.originalHeight = materialContainer.style.height;
          embedContainer.webkitRequestFullscreen();
          materialContainer.style.height = '100vh'
        }
      } else if (embedContainer.msRequestFullscreen) { // For Internet Explorer and Edge
        if (document.msFullscreenElement) {
          document.msExitFullscreen();
          materialContainer.style.height = this.originalHeight
        } else {
          this.originalHeight = materialContainer.style.height;
          embedContainer.msRequestFullscreen();
          materialContainer.style.height = '100vh'
        }
      }
    }
  },

  watch: {
    current_index(val) {
      if(this.course_content.length > 1) {
        if(!this.course_content[this.current_index].hasOwnProperty('is')) {
          if(!this.course_content[this.current_index].hasOwnProperty('course_evaluation') && !this.course_content[this.current_index].hasOwnProperty('module_evaluation')) {
            this.fetchAssessment()
          }
        } else if(this.course_content[this.current_index].is == 'topic' || this.course_content[this.current_index].is=='sub-topic') {
          if(this.course_content[this.current_index].learning_material) {
            if(this.course_content[this.current_index].learning_material.file_type == 's3_link') {
              document.getElementById('material-frame').src += '';
            }
          }
        }

        this.$router.replace({...this.$route.params, query: { uuid: this.course_content[this.current_index].uuid, timestamp: Date.now()}})

        
      }
    },

    startAssessment(val) {
      if(!val.status && val.action == 'ended') {
        this.post_type='ended'
        this.savedAnswers()
        this.quizStart = false
      }
    },

    successDialog(val) {
      if(val) {
        setTimeout(() => {
          this.successDialog= false
        }, 3000);
      }
    },

    evalSuccessDialog(val) {
      if(val) {
        setTimeout(() => {
          this.evalSuccessDialog= false
        }, 3000);
      }
    },

  }
}
</script>