<template>
    <v-container>
        <!-- <v-radio-group v-model="answer" readonly> -->
        <v-radio-group @change="changeAnswer" v-model="answers">
            <section class="d-flex flex-row align-center my-1" v-for="n in Object.keys(choices)" :key="n">
                <v-radio
                    hide-details
                    :value="n"
                    :readonly="preview"
                >
                </v-radio>
                <v-text-field class="mt-n1 col-sm-6" outlined dense hide-details readonly v-model="choices[n]"></v-text-field>
            </section>
        </v-radio-group>
    </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: ['choices', 'index', 'question_id', 'preview', 'answer'],
    data: () => ({
        radioGroup: [],
        hover: null,
        answers: ''
    }),
    mounted(){
        if(this.preview){
            this.answers = this.answer
        }
    },
    methods: {
        changeAnswer(e) {
            this.$emit('saveAnswer', {index: this.index, answer: e})
        }
    }
}
</script>