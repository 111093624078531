<template>
    <section>
      <v-sheet class="fade" color="transparent" :height="lists?'': '80vh'">
        <v-row align="center" justify="center" class="fill-height">
          <v-col cols="12" lg="9">
            <v-btn text @click="backAssessment" class=" f14 poppins primary--text">
              <v-icon small>mdi-chevron-left</v-icon>
              Back
            </v-btn>
            <v-sheet id="form" class="roboto custom-border border pa-10 mb-5">
                <div v-for="(item, i) in data.assessment_questions" :key="item.id">
                    <QuizHeader :i="i" :item="item"/>
                    <ChoicesWithAnswers 
                    :answer="answers.question_answer.find(_item => _item.assessment_question_id === item.assessment_answer.assessment_question_id)" 
                    :i="i" 
                    :item="item"
                    />
                    <v-divider class="my-10" v-if="i+1 != data.assessment_questions_count"/>
                </div>
                </v-sheet>
          </v-col>
        </v-row>
      </v-sheet>
    </section>
  </template>
  
  <script>
import OnePager from '../../components/user/assessment/OnePager.vue';
import ChoicesWithAnswers from '../../components/user/assessment/ChoicesWithAnswers.vue';
import QuizHeader from '../../components/user/assessment/QuizHeader.vue';

  export default {
    props: ['data', 'answers'],
  
    components: {
    OnePager,
    ChoicesWithAnswers,
    QuizHeader
},
  
    data: () => ({
      lists: true,
      indexFile: null,
      formHasErrors: false,
      errorFile: false,
      fileTypes: /(\.jpg|\.jpeg|\.png|\.docx|\.pdf)$/i /** regex */
    }),

    mounted(){
        // console.log(this.answers)
    },

    methods: {
        backAssessment(){
            this.$emit('backSubmitted')
        },
    }
  }
  </script>