<template>
  <v-container class="f14 secondary-1--text">
    <div class="d-flex align-center justify-space-between">
      <FormLabel :label="`Question ${i+1}:`" />
      <FormLabel :label="`${item.score} pts`" />
    </div>
    <div width="100%" class="question-div text-wrap my-5 f15" v-html="item.question ? $dompurifier(item.question) : ''"/>
    <div v-if="item.rubric_id">
      <FormLabel :label="`Rubrics:`" />
      <Rubric :rubric="item.rubric"/>
    </div>
  </v-container>
</template>

<script>
import Rubric from '../rubric/Rubric.vue'
export default {
  props: ['i', 'item'],
  components: {
    Rubric
  }
}
</script>
<style>
  .question-div {
    white-space: normal !important;
    word-wrap: break-word;
  }
</style>