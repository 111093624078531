<template>
  <div class="mb-7">
    <v-radio-group
      dense
      v-if="item.type_of_question != 'identification' && item.type_of_question != 'essay'"
      v-model="answer.answer"
      readonly
    >
      <v-row align="start" justify="center">
        <v-col cols="12" lg="10">
          <v-row no-gutters>
            <v-col
              cols="12"
              md="6"
              v-for="(choice, i) in item.assessment_answer.choices" :key="choice.id"
              class="d-flex align-center secondary-1--text f14 my-2"
            >
              <v-radio class="pt-2" :value="Object.keys(choice)[0]"/>
              <div class="roboto f14 fw500 secondary-1--text text-capitalize">{{choice[`${Object.keys(choice)[0]}`]}}</div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-radio-group>
    <v-text-field v-else-if="item.type_of_question == 'identification'"
        dense
        outlined
        class="col-12 col-lg-6 mx-7 general-custom-field f14 roboto fw500 secondary-1--text"
        v-model="answer.answer"
        placeholder="Type your answer here"
        hide-details
        readonly
    />
    <v-container>
      <v-container readonly v-if="item.type_of_question == 'essay' && answer.answer" v-html="answer.answer ? $dompurifier(answer.answer) : ''" class="essay-answer rounded-lg" outlined></v-container>
    </v-container>
    <v-text-field v-if="item.require_support_answer"
        dense
        outlined
        class="col-12 col-lg-6 mx-7 general-custom-field f14 roboto fw500 secondary-1--text"
        v-model="answer.support_answer"
        placeholder="Support your answer"
        hide-details
        readonly
    />
    <v-container v-if="item.require_file_attachment">
        <Document :file="answer.file" />
        <!-- <v-btn
            :href="answer.file" color="primary poppins" class="btn btn-success text-capitalize" download>Download</v-btn> -->
    </v-container>
    <v-container v-if="item.type_of_question == 'essay' || item.require_file_attachment" class="mt-5">
      <div class="poppins fw600 f13 secondary-4--text font-italic"> INSTRUCTOR'S FEEDBACK: </div>
      <v-textarea 
        outlined
        dense
        auto-grow
        rows="5"
        :value="answer.question_answer_feedback ? answer.question_answer_feedback.comment : null"
        class="poppins f14 fw400 rounded my-2"
        />
    </v-container>
  </div>
</template>

<style>
.essay-answer {
  background-color: rgba(130, 130, 130, 20%) !important;
}
</style>

<script>
import Document from "../../../constants/material/Document.vue";

export default {
  props: ['answer' , 'i', 'item', 'errorFile'],
  components: {
    Document
  },
  data: () => ({
  }),
  methods: {
    
  }
}
</script>